import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Review } from "src/@omnial/_models/customer/review.model";
import { environment } from "src/environments/environment";
import { RepositoryStaticService } from "../repository-static.service";
import { RepositoryService } from "../repository.service";

@Injectable()
export class ReviewsService implements OnDestroy {
  public reviews: BehaviorSubject<Review[]> = new BehaviorSubject<Review[]>(null);
  private $reviews: Review[] = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(public staticService: RepositoryStaticService,
    public repoService: RepositoryService) { }

  public load(init?: Review[]): void {
    if (init) {
      this.$reviews = init;
      this.reviews.next(this.$reviews);
      return;
    }
    if (!this.$reviews) {
      this.subscriptions.push(this.getTopReviews(environment.reviewCount).subscribe({
        next: (res: Review[]) => {
          this.$reviews = res;
          this.reviews.next(this.$reviews);
        },
        error: () => {
          this.$reviews = null;
          this.reviews.next(this.$reviews);
        }
      }));
    } else {
      this.reviews.next(this.$reviews);
    }
  }

  public getTopReviews(count: number, bypassCache?: boolean): Observable<Review[]> {
    return new Observable((observer) => {
      if (this.useCache && !bypassCache) {
        this.subscriptions.push(this.staticService.getTopReviews().subscribe({
          next: (staticRes) => {
            const reviews = staticRes as Review[];
            if (reviews) {
              observer.next(reviews);
              observer.complete();
            } else {
              this.subscriptions.push(this.repoService.getData(`Review/Top/${count}`).subscribe({
                next: (apiRes) => {
                  observer.next(apiRes as Review[]);
                  observer.complete();
                }
              }));
            }
          },
          error: () => {
            this.subscriptions.push(this.repoService.getData(`Review/Top/${count}`).subscribe({
              next: (apiRes) => {
                observer.next(apiRes as Review[]);
                observer.complete();
              }
            }));
          }
        }));
      } else {
        this.subscriptions.push(this.repoService.getData(`Review/Top/${count}`).subscribe({
          next: (apiRes) => {
            observer.next(apiRes as Review[]);
            observer.complete();
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
