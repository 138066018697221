import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Faqs } from "../_models/faqs.model";
import { ElfaService } from "./elfa.service";

@Injectable()
export class FaqService implements OnDestroy {
  public faqs: BehaviorSubject<Faqs> = new BehaviorSubject<Faqs>(null);
  private $faqs: Faqs = null;
  private subscriptions: Subscription[] = [];

  constructor(public elfaService: ElfaService) { }

  public load(): void {
    if (!this.$faqs) {
      this.subscriptions.push(this.elfaService.getFaqs().subscribe({
        next: (res: Faqs) => {
          this.$faqs = res;
          this.faqs.next(this.$faqs);
        },
        error: () => {
          this.$faqs = null;
          this.faqs.next(this.$faqs);
        }
      }));
    } else{
      this.faqs.next(this.$faqs);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }
}
