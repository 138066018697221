import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RepositoryStaticService } from '../repository-static.service';
import { RepositoryService } from '../repository.service';
import { BlogCategory } from 'src/@omnial/_models/catalog/blog.model';
import { ActivatedRoute } from '@angular/router';


@Injectable()
export class BlogCategoryService {
  public categories: BehaviorSubject<BlogCategory[]> = new BehaviorSubject<BlogCategory[]>(null);
  private $categories: BlogCategory[] = null;
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;

  constructor(
    private repoService: RepositoryService,
    private staticService: RepositoryStaticService,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  public load(bypassCache?: boolean): void {
    if (this.useCache && !bypassCache) {
      this.subscriptions.push(this.staticService.get('Blog').subscribe({
        next: (staticRes) => {
          this.$categories = staticRes as BlogCategory[];
          if (!this.$categories) {
            this.subscriptions.push(this.repoService.getData('SevenSpikes/BlogCategories').subscribe({
              next: (apiRes) => {
                this.$categories = apiRes as BlogCategory[];
                this.categories.next(this.$categories);
              }
            }));
          } else {
            this.categories.next(this.$categories);
          }
        },
        error: () => {
          this.subscriptions.push(this.repoService.getData('SevenSpikes/BlogCategories').subscribe({
            next: (apiRes) => {
              this.$categories = apiRes as BlogCategory[];
              this.categories.next(this.$categories);
            }
          }));
        }
      }));
    } else {
      this.subscriptions.push(this.repoService.getData('SevenSpikes/BlogCategories').subscribe({
        next: (apiRes) => {
          this.$categories = apiRes as BlogCategory[];
          this.categories.next(this.$categories);
        }
      }));
    }
  }
}
