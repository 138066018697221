import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    public router: Router) {
    super(document, new Platform(platformId));
  }

  _createContainer(): void {
      const container = document.createElement('div');
      container.classList.add('cdk-overlay-container');
      document.getElementById('app').appendChild(container);
      this._containerElement = container;
  }
}
