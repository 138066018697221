import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderDesktopComponent } from './header-desktop.component';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MenuModule } from 'src/@omnial/navigation/menu/menu.module';
import { TopicContentModule } from 'src/@omnial/shared/topic-content/topic-content.module';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MenuModule,
    TopicContentModule,
    ClientSharedModule
  ],
  declarations: [HeaderDesktopComponent],
  exports: [HeaderDesktopComponent]
})
export class HeaderDesktopModule { }
