import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

// Packages
import { NgxSpinnerModule } from 'ngx-spinner';
import { OverlayContainer, ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { register } from 'swiper/element/bundle';

// Setup
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AppSettings } from './app.settings';
import { environment } from '../environments/environment';
import { PagesModule } from 'src/@omnial/pages/pages.module';
import { CustomOverlayContainer } from 'src/@omnial/_utils/custom-overlay-container';

// Omnial Services
import { AccountService } from 'src/@omnial/_services/customer/account.service';
import { AfterpayService } from 'src/@omnial/_payments/afterpay.service';
import { AutoCompleteService } from 'src/@omnial/_services/search/auto-complete.service';
import { BlogCategoryService } from 'src/@omnial/_services/navigation/blog-category.service';
import { BlogSearchService } from 'src/@omnial/_services/search/blog-search.service';
import { BlogService } from 'src/@omnial/_services/catalog/blog.service';
import { BreadCrumbService } from 'src/@omnial/_services/navigation/breadcrumb.service';
import { CanonicalService } from 'src/@omnial/_services/navigation/canonical.service';
import { CartService } from 'src/@omnial/_services/order/cart.service';
import { CatalogService } from 'src/@omnial/_services/catalog/catalog.service';
import { CategoryService } from 'src/@omnial/_services/catalog/category.service';
import { CheckoutService } from 'src/@omnial/_services/order/checkout.service';
import { ClaimsService } from 'src/@omnial/_services/customer/claims.service';
import { ContactService } from 'src/@omnial/_services/customer/contact.service';
import { CurrencyService } from 'src/@omnial/_services/price/currency.service';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { DealerService } from 'src/@omnial/_services/dealers/dealer.service';
import { FacebookService } from 'src/@omnial/_services/external/facebook.service';
import { GoogleAddressService } from 'src/@omnial/_services/external/google.address.service';
import { GoogleGA4Service } from 'src/@omnial/_services/external/google.ga4.service';
import { KlaviyoService } from 'src/@omnial/_services/external/klaviyo.service';
import { ManufacturerService } from 'src/@omnial/_services/catalog/manufacturer.service';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { PayPalService } from 'src/@omnial/_payments/paypal.service';
import { ProductCategoryService } from 'src/@omnial/_services/catalog/product-category.service';
import { ProductManufacturerService } from 'src/@omnial/_services/catalog/product-manufacturer.service';
import { ProductsPagedService } from 'src/@omnial/_services/catalog/products-paged.service';
import { ProductSearchService } from 'src/@omnial/_services/search/product-search.service';
import { ReviewsService } from 'src/@omnial/_services/catalog/reviews.service';
import { ReviewService } from 'src/@omnial/_services/customer/review.service';
import { ScriptLoaderService } from 'src/@omnial/_services/external/script-load.service';
import { SearchService } from 'src/@omnial/_services/search/search.service';
import { SecurePayService } from 'src/@omnial/_payments/securepay.service';
import { SevenSpikesService } from 'src/@omnial/_services/catalog/seven.spikes.service';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { StripeServiceOmnial } from 'src/@omnial/_payments/stripe.service';
import { StarShipItService } from 'src/@omnial/_services/order/star-shipping.service';
import { StoreService } from 'src/@omnial/_services/catalog/store.service';
import { ThemeService } from 'src/@omnial/_services/catalog/theme.service';
import { TopicService } from 'src/@omnial/_services/catalog/topic.service';
import { TrackingService } from 'src/@omnial/_services/external/tracking.service';
import { UrlService } from 'src/@omnial/_services/catalog/url.service';
import { UserFormsService } from 'src/@omnial/_services/user-forms/user-forms.service';
import { VersionCheckService } from 'src/@omnial/_services/version-check.service';

// Client Specific Omnial Services
import { FaqService } from 'src/app/_services/faq.service';
import { InitService } from './_services/init.service';
import { SocialService } from './_services/social.service';
import { ArtistService } from './_services/artist.service';
import { ElfaService } from './_services/elfa.service';

// For swiper
register();

@NgModule({ declarations: [AppComponent], // Client Specific
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({ positionClass: 'toast-top-right', closeButton: true, progressBar: true }),
        RecaptchaV3Module,
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
        PagesModule], providers: [
        { provide: OverlayContainer, useClass: CustomOverlayContainer },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
        // Omnial Services
        StoreService,
        AccountService,
        AfterpayService,
        AppSettings,
        AutoCompleteService,
        BlogCategoryService,
        BlogSearchService,
        BlogService,
        BreadCrumbService,
        CanonicalService,
        CartService,
        CatalogService,
        CategoryService,
        ClaimsService,
        ContactService,
        CheckoutService,
        CurrencyService,
        CustomerService,
        DealerService,
        FacebookService,
        GoogleAddressService,
        GoogleGA4Service,
        KlaviyoService,
        ManufacturerService,
        MenuService,
        PayPalService,
        ProductCategoryService,
        ProductManufacturerService,
        ProductsPagedService,
        ProductSearchService,
        ReviewService,
        ReviewsService,
        ScriptLoaderService,
        SearchService,
        SecurePayService,
        SevenSpikesService,
        SiteMapService,
        StripeServiceOmnial,
        StarShipItService,
        ThemeService,
        TopicService,
        TrackingService,
        VersionCheckService,
        UrlService,
        UserFormsService,
        InitService, // Client Specific
        ElfaService, // Client Specific
        SocialService, // Client Specific
        ArtistService, // Client Specific
        FaqService,
        provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-CSRF-TOKEN'
        }))
    ] })
export class AppModule { }
