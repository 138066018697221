import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { AfterpayService } from "src/@omnial/_payments/afterpay.service";
import { BlogService } from "src/@omnial/_services/catalog/blog.service";
import { StoreService } from "src/@omnial/_services/catalog/store.service";
import { ThemeService } from "src/@omnial/_services/catalog/theme.service";
import { DealerService } from "src/@omnial/_services/dealers/dealer.service";
import { MenuService } from "src/@omnial/_services/navigation/menu.service";
import { SiteMapService } from "src/@omnial/_services/navigation/sitemap.service";
import { RepositoryStaticService } from "src/@omnial/_services/repository-static.service";
import { environment } from "src/environments/environment";
import { Init } from "../_models/init.model";
import { ArtistService } from "./artist.service";
import { SocialService } from "./social.service";



@Injectable()
export class InitService {
  private subscriptions: Subscription[] = [];
  private useCache = environment.useCache;
  constructor(
    public storeService: StoreService,
    public sitemapService: SiteMapService,
    public menuService: MenuService,
    public themeService: ThemeService,
    public artistService: ArtistService,
    public blogService: BlogService,
    public socialService: SocialService,
    public dealerService: DealerService,
    public staticService: RepositoryStaticService,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment && fragment === 'CacheBust') {
        this.useCache = false;
      }
    });
  }

  public initialize(): Observable<Init> {
    // One call to get the 'static' info needed to setup the store
    return new Observable((observer) => {
      if (this.useCache) {
        this.subscriptions.push(this.staticService.get('Elfa/Init').subscribe({
          next: (apiRes: Init) => {
            if (apiRes?.storeSettings) {
              this.storeService.load(apiRes.storeSettings);
            }
            if (apiRes?.sitemap) {
              this.sitemapService.load(apiRes.sitemap);
            }
            if (apiRes?.menu) {
              this.menuService.load(apiRes.menu);
            }
            if (apiRes?.themeSettings) {
              this.themeService.load(apiRes.themeSettings);
            }
            if (apiRes?.artists) {
              this.artistService.load(apiRes.artists);
            }
            if (apiRes?.socialLinks) {
              this.socialService.load(apiRes.socialLinks);
            }
            if (apiRes?.blog) {
              this.blogService.load(apiRes.blog);
            }
            if (apiRes?.dealers) {
              this.dealerService.getDealers();
            }
            observer.next(apiRes);
            observer.complete();
          },
          error: () => {
            observer.next(null);
            observer.complete();
          }
        }));
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }
}
