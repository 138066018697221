import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MenuMegaComponent } from './menu-mega/menu-mega.component';
import { MenuComponent } from './menu.component';
import { ClientNavigationModule } from 'src/app/navigation/_client/client.navigation.module';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';
import { TopicModule } from 'src/@omnial/pages/topic/topic.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    ClientNavigationModule,
    ClientSharedModule,
    TopicModule
  ],
  declarations: [MenuComponent, MenuMegaComponent],
  exports: [MenuComponent, MenuMegaComponent]
})
export class MenuModule { }
