import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SearchResults } from "src/@omnial/_models/search/search-results.model";
import { RepositorySearchService } from "../repository-search.service";

@Injectable()
export class SearchService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(public repoSearchService: RepositorySearchService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe() });
    }
  }

  public search(term: string): Observable<SearchResults> { // This is raw for the Admin mainly
    return new Observable((observer) => {
      this.subscriptions.push(this.repoSearchService.search(term).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        }
      }));
    });
  }

  public products(term: string): Observable<SearchResults> { // This is raw for the Admin mainly
    return new Observable((observer) => {
      this.subscriptions.push(this.repoSearchService.searchProducts(term).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        }
      }));
    });
  }

  public productExtras(term: string): Observable<SearchResults> { // This is raw for the Admin mainly
    return new Observable((observer) => {
      this.subscriptions.push(this.repoSearchService.searchProducts(term).subscribe({
        next: (res) => {
          observer.next(res);
          observer.complete();
        }
      }));
    });
  }
}
