import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlogService } from 'src/@omnial/_services/catalog/blog.service';
import { CategoryService } from 'src/@omnial/_services/catalog/category.service';
import { StoreService } from 'src/@omnial/_services/catalog/store.service';
import { ThemeService } from 'src/@omnial/_services/catalog/theme.service';
import { DealerService } from 'src/@omnial/_services/dealers/dealer.service';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { ArtistService } from './_services/artist.service';
import { InitService } from './_services/init.service';
import { SocialService } from './_services/social.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = [];

    constructor(public storeService: StoreService,
    public sitemapService: SiteMapService,
    public menuService: MenuService,
    public artistService: ArtistService,
    public dealerService: DealerService,
    public themeService: ThemeService,
    public blogService: BlogService,
    public socialService: SocialService,
    public initService: InitService,
    public categoryService: CategoryService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.initService.initialize().subscribe((res) => {
      if (!res?.storeSettings) {
        this.storeService.load();
      }
      if (!res?.sitemap) {
        this.sitemapService.load();
      }
      if (!res?.menu) {
        this.menuService.load();
      }
      if (!res?.themeSettings) {
        this.themeService.load();
      }
      if (!res?.socialLinks) {
        this.socialService.load();
      }
      if (!res?.blog) {
        this.blogService.load();
      }
      if (!res?.artists) {
        this.artistService.load();
      }
      if (!res?.dealers) {
        this.dealerService.load();
      }
      if (!res?.dealerSettings) {
        this.dealerService.loadSettings();
      }
      if (!res?.categories) {
        this.categoryService.load();
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }
}

