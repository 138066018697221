import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavMenuComponent } from './sidenav-menu.component';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule
  ],
  declarations: [SidenavMenuComponent],
  exports: [SidenavMenuComponent]
})
export class SidenavMenuModule { }
