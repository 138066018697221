import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavMegaComponent } from './sidenav-mega.component';
import { RouterModule } from '@angular/router';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClientNavigationModule } from 'src/app/navigation/_client/client.navigation.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    LazyLoadImageModule,
    ClientNavigationModule
  ],
  declarations: [SidenavMegaComponent],
  exports: [SidenavMegaComponent]
})
export class SidenavMegaMenuModule { }
