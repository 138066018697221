import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialBaseModule } from 'src/@omnial/material.base.module';
import { MaterialExtendModule } from 'src/@omnial/material.extend.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TopicContentModule } from '../topic-content/topic-content.module';
import { SubscribeModule } from '../subscribe/subscribe.module';
import { ClientSharedModule } from 'src/app/shared/_client/client.shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialBaseModule,
    MaterialExtendModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    TopicContentModule,
    SubscribeModule,
    ClientSharedModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
