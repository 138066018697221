import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from 'src/@omnial/pages/pages.component';
import { ContentComponent } from 'src/app/_content/content.component';
import { WishlistComponent } from 'src/@omnial/pages/wishlist/wishlist.component';
import { SignInComponent } from 'src/@omnial/pages/account/sign-in/sign-in.component';
import { CheckoutComponent } from 'src/@omnial/pages/checkout/checkout.component';
import { CartComponent } from 'src/@omnial/pages/cart/cart.component';
import { AccountComponent } from 'src/@omnial/pages/account/account.component';
import { BrandLandComponent } from 'src/@omnial/pages/brand-land/brand-land.component';
import { CategoryLandComponent } from 'src/@omnial/pages/category-land/category-land.component';
import { ProductSearchComponent } from 'src/@omnial/pages/product-search/product-search.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent, children: [
      { path: 'search', loadChildren: () => import('src/@omnial/pages/product-search/product-search.module').then(m => m.ProductSearchModule) },
      { path: 'search.html', loadChildren: () => import('src/@omnial/pages/product-search/product-search.module').then(m => m.ProductSearchModule) },
      { path: 'account', loadChildren: () => import('src/@omnial/pages/account/account.module').then(m => m.AccountModule) },
      { path: 'orderdetails', loadChildren: () => import('src/@omnial/pages/account/account.module').then(m => m.AccountModule) },
      { path: 'cart', loadChildren: () => import('src/@omnial/pages/cart/cart.module').then(m => m.CartModule) },
      { path: 'checkout', loadChildren: () => import('src/@omnial/pages/checkout/checkout.module').then(m => m.CheckoutModule) },
      { path: 'checkout/afterpay-return', loadChildren: () => import('src/@omnial/pages/checkout/checkout.module').then(m => m.CheckoutModule) },
      { path: 'checkout/afterpay-cancel', loadChildren: () => import('src/@omnial/pages/checkout/checkout.module').then(m => m.CheckoutModule) },
      { path: 'sign-in', loadChildren: () => import('src/@omnial/pages/account/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'passwordrecovery/confirm', loadChildren: () => import('src/@omnial/pages/account/sign-in/sign-in.module').then(m => m.SignInModule) },
      { path: 'wishlist', loadChildren: () => import('src/@omnial/pages/wishlist/wishlist.module').then(m => m.WishlistModule) },
      { path: 'products', loadChildren: () => import('../@omnial/pages/category-land/category-land.module').then(m => m.CategoryLandModule), data: { preload: false, title: 'Browse By Brand', description: 'Check out all the Categories', animationState: 'Right' } },
      { path: 'products.html', loadChildren: () => import('../@omnial/pages/category-land/category-land.module').then(m => m.CategoryLandModule), data: { preload: false, title: 'Browse By Brand', description: 'Check out all the Categories', animationState: 'Right' } },
      { path: 'brands', loadChildren: () => import('../@omnial/pages/brand-land/brand-land.module').then(m => m.BrandLandModule), data: { preload: false, title: 'Browse By Brand', description: 'Check out all the brands', animationState: 'Right' } },
      { path: 'brands.html', loadChildren: () => import('../@omnial/pages/brand-land/brand-land.module').then(m => m.BrandLandModule), data: { preload: false, title: 'Browse By Brand', description: 'Check out all the brands', animationState: 'Right' } },
      { path: 'manufacturers', loadChildren: () => import('../@omnial/pages/brand-land/brand-land.module').then(m => m.BrandLandModule), data: { preload: false, title: 'Browse By Manufacturer', description: 'Check out all the brands', animationState: 'Right' } },
      { path: 'manufacturers.html', loadChildren: () => import('../@omnial/pages/brand-land/brand-land.module').then(m => m.BrandLandModule), data: { preload: false, title: 'Browse By Manufacturer', description: 'Check out all the brands', animationState: 'Right' } },

      // Pages as "Dummies" to split up the JS
      { path: 'omnial/omnial-blog', loadChildren: () => import('../@omnial/pages/blog/blog.module').then(m => m.BlogModule) },
      { path: 'omnial/omnial-blog-category', loadChildren: () => import('../@omnial/pages/blog-category/blog-category.module').then(m => m.BlogCategoryModule) },
      { path: 'omnial/omnial-blog-post', loadChildren: () => import('../@omnial/pages/blog-post/blog-post.module').then(m => m.BlogPostModule) },
      { path: 'omnial/omnial-category', loadChildren: () => import('../@omnial/pages/category/category.module').then(m => m.CategoryModule) },
      { path: 'omnial/omnial-category-content', loadChildren: () => import('../@omnial/pages/category-content/category-content.module').then(m => m.CategoryContentModule) },
      { path: 'omnial/omnial-home', loadChildren: () => import('../@omnial/pages/home/home.module').then(m => m.HomeModule) },
      { path: 'omnial/omnial-manufacturer', loadChildren: () => import('../@omnial/pages/manufacturer/manufacturer.module').then(m => m.ManufacturerModule) },
      { path: 'omnial/omnial-not-found', loadChildren: () => import('../@omnial/pages/not-found/not-found.module').then(m => m.NotFoundModule) },
      { path: 'omnial/omnial-product', loadChildren: () => import('../@omnial/pages/product/product.module').then(m => m.ProductModule) },
      { path: 'omnial/omnial-product-search', loadChildren: () => import('../@omnial/pages/product-search/product-search.module').then(m => m.ProductSearchModule) },
      { path: 'omnial/omnial-topic', loadChildren: () => import('../@omnial/pages/topic/topic.module').then(m => m.TopicModule) },
      { path: 'omnial/omnial-user-form', loadChildren: () => import('../@omnial/pages/user-form/user-form.module').then(m => m.UserFormModule) },
      { path: 'omnial/omnial-client-page', loadChildren: () => import('./pages/_client/client.page.module').then(m => m.ClientPageModule) },
      // Pages as "Dummies" to split up the JS

      // And the final entry to get Managed Content
      { path: '**', loadChildren: () => import('./_content/content.module').then(m => m.ContentModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
